import { Stack, Text } from '@chakra-ui/react';
import { NiceModalHandler } from '@ebay/nice-modal-react';
import React from 'react';
import { DeleteConfirmModal } from '../../components/generic/DeleteConfirmModal';

interface Props {
  modal: NiceModalHandler<Record<string, unknown>>;
  isLoading: boolean;
  onDelete: () => void;
}

export const FileDeleteModal = (props: Props) => {
  const { modal, onDelete, isLoading } = props;

  return (
    <DeleteConfirmModal
      modal={modal}
      heading="Do you want to delete this file?"
      description={
        <Stack mb={6} spacing={4}>
          <Text>
            Deleting this file <strong>will remove associated data.</strong>
          </Text>
        </Stack>
      }
      requireConfirm
      isLoading={isLoading}
      onDelete={async () => onDelete()}
    />
  );
};
