import { Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { DeleteConfirmModal } from '../../../../components/generic/DeleteConfirmModal';
import { createModal } from '../../../../components/generic/Modal';
import { useAppContext } from '../../../../hooks/useAppContext';

interface Props {
  onDelete: () => void;
}

export const ParticipantWidgetDeleteModal = createModal<Props>((props) => {
  const { onDelete, modal } = props;
  const { successToast } = useAppContext();

  const onDeletePrime = React.useCallback(async () => {
    onDelete();
    successToast('Widget removed successfully');
  }, [onDelete, successToast]);

  return (
    <DeleteConfirmModal
      modal={modal}
      heading="Do you want to delete this widget?"
      description={
        <Stack mb={6} spacing={4}>
          <Text>Deleting this widget cannot be undone</Text>
        </Stack>
      }
      requireConfirm
      onDelete={onDeletePrime}
    />
  );
});
