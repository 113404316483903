import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { NiceModalHandler } from '@ebay/nice-modal-react';
import { FILE_SIZE_LIMIT_32MB_BYTES, renderPiccoloError } from '@piccolohealth/pbs-common';
import { FileUploadControl, FileUploadItemsProgress, useFileUpload } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import axios from 'axios';
import React from 'react';
import { useAppContext } from '../../hooks/useAppContext';
import { asPiccoloError } from '../../utils/errors';

interface Props {
  modal: NiceModalHandler<Record<string, unknown>>;
  url: string;
  files?: File[];
  onUploaded?: (status: 'success' | 'error') => void;
}

export const FileUploadModal = (props: Props) => {
  const { url, onUploaded, modal, files } = props;
  const { auth } = useAppContext();
  const { getAccessTokenSilently } = auth;

  const { visible, remove, hide } = modal;

  const sendFile = React.useCallback(
    async (
      file: File,
      onProgress: (opts: { percentage: number; total?: number; loaded?: number }) => void,
    ) => {
      const accessToken = await getAccessTokenSilently();

      const formData = new FormData();
      formData.append('files', file);

      const fileSizeBeforeUpload = file.size;

      await axios(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        onUploadProgress: (event) => {
          if (!event.total) {
            return;
          }

          onProgress({
            percentage: (event.loaded / event.total) * 100,
            total: event.total,
            loaded: event.loaded,
          });
        },
      })
        .then((res) => {
          const fileSizeAfterUpload = res.data.size;

          if (fileSizeAfterUpload !== fileSizeBeforeUpload) {
            Sentry.captureEvent({
              message: 'File size mismatch after upload',
              extra: {
                fileName: file.name,
                fileSizeBeforeUpload,
                fileSizeAfterUpload,
              },
            });
          }
        })
        .catch((error) => {
          const piccoloError = asPiccoloError(error);
          piccoloError.message = renderPiccoloError(piccoloError).message;
          throw piccoloError;
        });

      onProgress({ percentage: 100 });
    },
    [getAccessTokenSilently, url],
  );

  const fileUploader = useFileUpload({
    sendFile,
    concurrency: 4,
    autoStart: true,
    maxFileSize: FILE_SIZE_LIMIT_32MB_BYTES,
  });

  const { onFilesChange, reset, status, fileProgress } = fileUploader;

  React.useEffect(() => {
    if (files && !P.isEmpty(files)) {
      onFilesChange(files);
    }
  }, [onFilesChange, files]);

  React.useEffect(() => {
    if (status === 'finished') {
      onUploaded?.('success');
    } else if (status === 'errored') {
      onUploaded?.('error');
    }
  }, [status, onUploaded]);

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={remove} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add files</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {P.isEmpty(fileUploader.files) && (
            <FileUploadControl
              multiple={true}
              files={fileUploader.files}
              onFilesChange={onFilesChange}
              status={status}
            />
          )}
          <FileUploadItemsProgress files={Object.values(fileProgress)} />
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={reset} size="sm">
            Reset
          </Button>
          <Button colorScheme="purple" mr={3} onClick={hide} size="sm">
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
