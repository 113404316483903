import { Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { DeleteConfirmModal } from '../../../components/generic/DeleteConfirmModal';
import { createModal } from '../../../components/generic/Modal';
import { useDeleteLabelMutation } from '../../../graphql/hooks/useDeleteLabelMutation';
import { useAppContext } from '../../../hooks/useAppContext';

interface Props {
  labelId: string;
}

export const LabelDeleteModal = createModal<Props>((props) => {
  const { labelId, modal } = props;
  const { organization, successToast, errorToast } = useAppContext();
  const mutation = useDeleteLabelMutation({});

  const onDelete = React.useCallback(async () => {
    await mutation
      .mutateAsync({
        organizationId: organization.id,
        labelId,
      })
      .then(async () => {
        successToast('Label deleted successfully');
      })
      .catch((err) => {
        errorToast(`Error deleting label: ${err.message}`);
      });
  }, [mutation, organization, labelId, successToast, errorToast]);

  return (
    <DeleteConfirmModal
      modal={modal}
      heading="Do you want to delete this label?"
      description={
        <Stack mb={6} spacing={4}>
          <Text>
            Deleting this label <strong>cannot be undone.</strong>
          </Text>
        </Stack>
      }
      requireConfirm
      onDelete={onDelete}
      isLoading={mutation.isLoading}
    />
  );
});
