import { Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { DeleteConfirmModal } from '../../components/generic/DeleteConfirmModal';
import { createModal } from '../../components/generic/Modal';
import { useDeleteUserMutation } from '../../graphql/hooks/useDeleteUserMutation';
import { useAppContext } from '../../hooks/useAppContext';

interface Props {
  userId: string;
}

export const UserDeleteModal = createModal<Props>((props) => {
  const { userId, modal } = props;
  const { organization, successToast, errorToast } = useAppContext();
  const mutation = useDeleteUserMutation();

  const onDelete = React.useCallback(async () => {
    await mutation
      .mutateAsync({
        organizationId: organization.id,
        userId,
      })
      .then(async () => {
        successToast('User deleted successfully');
      })
      .catch((err) => {
        errorToast(`Error deleting user: ${err.message}`);
      });
  }, [organization.id, mutation, userId, successToast, errorToast]);

  return (
    <DeleteConfirmModal
      modal={modal}
      heading="Do you want to delete this user?"
      description={
        <Stack mb={6} spacing={4}>
          <Text>
            Deleting this user <strong>cannot be undone.</strong>
          </Text>
        </Stack>
      }
      requireConfirm
      onDelete={onDelete}
      isLoading={mutation.isLoading}
    />
  );
});
