import { Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { DeleteConfirmModal } from '../../components/generic/DeleteConfirmModal';
import { createModal } from '../../components/generic/Modal';
import { useDeleteParticipantNoteMutation } from '../../graphql/hooks/useDeleteParticipantNoteMutation';
import { useAppContext } from '../../hooks/useAppContext';

interface Props {
  participantId: string;
  participantNoteId: string;
  onDelete?: () => void;
}

export const ParticipantNoteDeleteModal = createModal<Props>((props) => {
  const { participantId, participantNoteId, modal, onDelete } = props;
  const { organization, successToast, errorToast } = useAppContext();
  const mutation = useDeleteParticipantNoteMutation();

  const onDeletePrime = React.useCallback(async () => {
    await mutation
      .mutateAsync({
        organizationId: organization.id,
        participantId,
        participantNoteId,
      })
      .then(() => {
        successToast('Note deleted successfully');
        onDelete?.();
      })
      .catch((err) => {
        errorToast(`Error deleting note: ${err.message}`);
      });
  }, [
    mutation,
    organization.id,
    participantId,
    participantNoteId,
    successToast,
    onDelete,
    errorToast,
  ]);

  return (
    <DeleteConfirmModal
      modal={modal}
      heading="Do you want to delete this note?"
      description={
        <Stack mb={6} spacing={4}>
          <Text>Deleting this note cannot be undone</Text>
        </Stack>
      }
      requireConfirm
      isLoading={mutation.isLoading}
      onDelete={onDeletePrime}
    />
  );
});
