import gql from 'graphql-tag';

export const Organization = gql`
  query Organization($organizationId: ID!) {
    organization(organizationId: $organizationId) {
      id
      name
      logo
      timezone
      locale
      forms {
        id
        type
        name
        url
        labelMappings {
          create {
            id
            name
            description
            color
          }
          update {
            id
            name
            description
            color
          }
        }
      }
      featureFlags
      locations {
        id
        name
        color
      }
    }
  }
`;

export const OrganizationMinimal = gql`
  query OrganizationMinimal($organizationId: ID!) {
    organization(organizationId: $organizationId) {
      id
      name
      logo
      timezone
      locale
      featureFlags
    }
  }
`;
