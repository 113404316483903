import gql from 'graphql-tag';

export const CurrentUser = gql`
  query CurrentUser {
    user {
      id
      name
      email
      picture
      title
      practitionerNumber
      featureFlags
      extraPermissions
      organizationMemberships {
        organizationId
        name
        roles
      }
      intercomHash
      settings {
        defaultOrganization
      }
    }
  }
`;

export const UserMinimalQuery = gql`
  query UserMinimal($organizationId: ID!, $userId: ID!) {
    organization(organizationId: $organizationId) {
      user(userId: $userId) {
        id
        name
      }
    }
  }
`;

export const UserQuery = gql`
  query User($organizationId: ID!, $userId: ID!) {
    organization(organizationId: $organizationId) {
      user(userId: $userId) {
        id
        name
        email
        title
        practitionerNumber
        picture
        featureFlags
        extraPermissions
        organizationMemberships {
          organizationId
          name
          roles
          locations {
            id
            name
          }
        }
      }
    }
  }
`;
