import { Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DeleteConfirmModal } from '../../components/generic/DeleteConfirmModal';
import { createModal } from '../../components/generic/Modal';
import { useDeleteParticipantDocumentMutation } from '../../graphql/hooks/useDeleteParticipantDocumentMutation';
import { useAppContext } from '../../hooks/useAppContext';

interface Props {
  participantId: string;
  participantDocumentId: string;
}

export const ParticipantDocumentDeleteModal = createModal<Props>((props) => {
  const { participantId, participantDocumentId, modal } = props;

  const { organization, successToast, errorToast } = useAppContext();
  const mutation = useDeleteParticipantDocumentMutation();
  const navigate = useNavigate();

  const onDelete = React.useCallback(async () => {
    await mutation
      .mutateAsync({
        organizationId: organization.id,
        participantId,
        participantDocumentId,
      })
      .then(async () => {
        successToast('Document deleted successfully');
        navigate(`/organizations/${organization.id}/participants/${participantId}/documents`);
      })
      .catch((err) => {
        errorToast(`Error deleting document: ${err.message}`);
      });
  }, [
    mutation,
    organization.id,
    participantId,
    participantDocumentId,
    successToast,
    navigate,
    errorToast,
  ]);

  return (
    <DeleteConfirmModal
      modal={modal}
      heading="Do you want to delete this document?"
      description={
        <Stack mb={6} spacing={4}>
          <Text>
            Deleting this document <strong>will remove associated data.</strong>
          </Text>
        </Stack>
      }
      requireConfirm
      isLoading={mutation.isLoading}
      onDelete={async () => onDelete()}
    />
  );
});
