import gql from 'graphql-tag';

export const Incidents = gql`
  query Incidents($organizationId: ID!, $request: GetPaginatedIncidentsRequest) {
    organization(organizationId: $organizationId) {
      incidents(request: $request) {
        pagination {
          total
          remaining
          offset
          limit
          currentPage
          totalPages
          hasNextPage
          hasPreviousPage
        }
        results {
          id
          shortId
          organizationId
          location {
            id
            name
            color
          }
          timestamp
          setting
          status
          severity
          reporter {
            id
            name
            email
            title
            picture
          }
          reviewers {
            id
            name
            email
            title
            picture
          }
          participant {
            id
            firstName
            lastName
            ndisNumber
          }
          labels {
            id
            name
            color
            description
          }
          description
          ndisReportableIncident
          witnesses {
            name
            phone
            email
            description
          }
          files {
            id
            incidentId
            name
            uploader {
              id
              name
              email
              title
              picture
            }
            size
            viewUrl
            downloadUrl
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;
